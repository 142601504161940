import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Back Extension 4×10 & Side Planks 4x:30/side`}</p>
    <p>{`Deadlifts 8-8-8-8 to an 8RM`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`400M Run`}</p>
    <p>{`21-K2E’s`}</p>
    <p>{`15-Deadlifts (155/105, rx+ 185/125)`}</p>
    <p>{`9-Hang Power Cleans (155/105, rx+ 185/125)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start Nov 26th.  For more info email
Eric at: fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      